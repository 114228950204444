import { mainDiv, mainInnerDiv, slider, carouselImage, slideContainerDiv } from './CourseCarousel.module.css'
import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Slider from "react-slick";
import { navigate } from 'gatsby';
import useWindowSize from "../../utils/useWindowSize";

const CourseCarousel = () => {
    
    const { width } = useWindowSize();

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: width < 900 ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };

    return (
        <div className={mainDiv}>
            <div className={mainInnerDiv}>
                <Slider {...settings} className={slider}>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta de Chocolate 1.jpg" alt="crema catalana" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta Oreo 2.jpg" alt="pastafrola" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta de Chocolate 3.jpg" alt="Torta de Chocolate" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta Frozen 1.jpg" alt="postre serenito" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta de Unicornio 1.png" alt="lemon pie" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta Oreo 3.jpg" alt="postre serenito" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta Frozen 2.jpg" alt="postre serenito" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta Oreo 4.jpg" alt="postre serenito" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta de Chocolate 2.jpg" alt="postre serenito" width={1000}/>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../../images/courses/Torta Frozen 3.jpg" alt="postre serenito" width={1000}/>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default CourseCarousel