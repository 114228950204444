import { mainStyles, topSection, topSectionShadowDiv, topSectionMainTitle,
    topSectionContent, topSectionMainSubTitle, topSectionBuyButton, topSectionBuyButtonDiv,
    topSectionPrice, textImagesMainDiv, textImagesLeftDiv, textImagesRightDiv,
    textImagesLeftTitleDiv, textImagesLeftTextDiv, textImagesRightImage, mainContentDiv,
    courseContentDiv, courseContentTitleDiv, courseContentSectionsMainDiv, courseContentSectionOpenedDiv,
    courseContentSectionInnerDiv, courseContentSectionInnerNumberDiv, courseContentSectionInnerNameDiv,
    arrowDown, arrowUp, courseContentSectionInnerArrowDiv, courseContentSectionClosedDiv,
    courseContentChapterSectionsMainDiv, courseContentChapterSectionNameDiv, courseContentChapterSectionMainDiv, 
    courseContentChapterSectionFreeDiv, courseContentChapterSectionIconSvg, courseContentChapterSectionIconPath,
    courseContentChapterSectionNameAndFreeDiv, textImagesLeftSecondTitleDiv, textImagesLeftSecondTextDiv,
    materialsUl, faqMainDiv, faqTitleDiv, faqQuestionDiv, faqAnswerDiv, topSectionFreeSectionButton, 
    summaryMainDiv, summaryLeftDiv, personImage, summaryPersonDiv, summaryPersonDetailsTopDiv,
    summaryPersonDetailsBottomDiv, summaryPersonDetailsDiv, summaryRightDiv, summaryIncludedTitleDiv,
    summaryIncludedUl, summaryButtonsDiv, summaryBuyButton, summaryFreeSectionButton, timeLeftDiv,
    summaryRightImg, summaryRightImgShadow, playButton, courseContentSectionsActionsMainDiv,
    courseContentSectionsActionDiv, courseContentSectionsActionSeparatorDiv, teacherMainDiv,
    teacherTitleDiv, teacherContentMainDiv, teacherContentLeftDiv, teacherContentRightDiv,
    teacherContentLeftImg, teacherContentRightTitleDiv, teacherContentRightSecondDiv,
    teacherContentRightTextDiv, teacherContentLeftOuterDiv, fixedBottomBarMainDiv, fixedBottomBarInnerDiv,
    fixedBottomBuyButton, fixedBottomFreeSectionButton, hideDiv, videoCoverMainDiv, videoCoverIFrame,
    interestedModalBackgroundDiv, interestedModalMainDiv, topSectionPriceDiv, topSectionPricePrevious,
    fixedBottomBuyButtonPreviousPrice, topSectionRating, topSectionRatingText, reviewsMainDiv,
    reviewsTitleDiv, topSectionImageDesktop, topSectionImageMobile, flag, bannerBuyButtonPrice,
    fixedBottomBuyInnerDiv, topSectionBuyMainTextButton, topSectionBuyInnerContainerButton,
    fixedBottomBuyMainTextButton } from './curso-tortas-de-cumpleanos.module.css'
    import React, { useEffect } from "react"
import Footer from "../../components/footer"
import Loading from "../../components/Loading"
import CourseNavBar from "../../components/Courses/CourseNavBar"
import CourseCarousel from "../../components/Courses/CourseCarousel"
import CourseReviewsCarousel from "../../components/Courses/CourseReviewsCarousel"
import InterestedModal from "../../components/Courses/InterestedModal"
import Star from "../../components/Courses/Star"
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from "react-helmet"
import useWindowScroll from "../../utils/useWindowScroll";
import pricesContext  from "../../utils/pricesContext";
import userContext from "../../utils/userContext"
import buyUtils from "../../utils/buyUtils"
import fbTrackUtils from "../../utils/fbTrackUtils"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
    {
        allMysqlAllCourses(filter: {name: {eq: "Curso Tortas de Cumpleaños"}}) {
          nodes {
            canBuy,
            courseChapters {
              name
              courseSections {
                name
                isFree
                icon
              }
            }
            coursePrices {
                price
                coursePriceCountries {
                  cod_country
                  coin_symbol
                  coin_cod
                }
            }
          }
        }
      }
    `)
    
    const [ loading, setLoading ] = React.useState(false);
    const [ contentsOpened, setContentsOpened ] = React.useState(getInitialContentsOpened());
    const [ timeLeft, setTimeLeft ] = React.useState("");
    const [ showCoverVideo, setShowCoverVideo ] = React.useState(false);
    const [ showInterestedVideo, setShowInterestedVideo ] = React.useState(false);
    const { scrollY } = useWindowScroll();

    useEffect(() => {
        fbTrackUtils.trackViewContent();
        fbTrackUtils.trackLongViewContent();
    }, [])

    function getInitialContentsOpened() {
        var contentsOpenedTmp = [true];
        for (var i = 1; i < data.allMysqlAllCourses.nodes[0].courseChapters.length; i++) {
            contentsOpenedTmp[i] = false;
        }

        return contentsOpenedTmp;
    }

    function openContent(index) {
        setContentsOpened(contentsOpened => {
            return contentsOpened.map((item, j) => {
              return j === index ? !item : item
            })
        })
    }

    function showAllContents() {
        setContentsOpened(contentsOpened => {
            return contentsOpened.map(() => {
              return true;
            })
        })
    }

    function hideAllContents() {
        setContentsOpened(contentsOpened => {
            return contentsOpened.map(() => {
              return false;
            })
        })
    }

    function getSectionsQuantity() {
        var retval = 0;
        for (var i = 1; i < data.allMysqlAllCourses.nodes[0].courseChapters.length; i++) {
            var chapter = data.allMysqlAllCourses.nodes[0].courseChapters[i];

            retval += chapter.courseSections.length;
        }
        
        return retval;
    }

    var materials = ["Batidor (eléctrico o de mano)", "Molde para hacer los bizcochuelos", "Balanza", "Mangas de pastelería", "Picos para las decoraciones", "Horno"];
    var included = ["1 h 25 min de vídeo profesional", "20 recetas", getSectionsQuantity() + " lecciones", "Acceso ilimitado a todo el contenido por un año"]
    var faq = [{
        "question": "¿Cuándo empiezan y cuando terminan los cursos?",
        "answer": "Los cursos son 100% online, por lo puedes llevarlo a tu ritmo, empezarlo y terminarlo en cualquier momento. Podés pasar rápido lo que te quedo claro y volver a ver con detenimiento las partes donde te quedaron dudas."
    },{
        "question": "¿Necesito tener algún conocimiento de pastelería previo?",
        "answer": "¡Para nada! Este curso está diseñado tanto para personas que no tienen ninguna experiencia en pastelería, como para quienes son expertos y están buscando aprender otras alternativas en sus preparaciones."
    },{
        "question": "¿Cómo comienzo el curso?",
        "answer": "Una vez hayas comprado el curso tendrás acceso en su totalidad para arrancar cuando quieras."
    },{
        "question": "¿Necesito algún ingrediente especial o difícil de conseguir para las recetas?",
        "answer": "No, todos los ingredientes usados en las recetas del curso son muy fáciles de encontrar y se pueden conseguir en cualquier supermercado sin problemas."
    }];

    function updateTimeLeft() {
        var now = new Date();
        var endPromoTime = new Date();
        endPromoTime.setHours(3,0,0,0);
        if (now > endPromoTime) {
            endPromoTime.setDate(endPromoTime.getDate() + 1);
        }
        var secondsToEndPromoTime = (endPromoTime.getTime() - now.getTime())/1000;
        var hours = Math.floor(secondsToEndPromoTime/3600);
        var minutes = Math.ceil(secondsToEndPromoTime/60 - hours*60);

        if (minutes == 60) {
            hours++;
            minutes = 0;
        }

        var finalStr = "";
        if (hours == 1) {
            finalStr += hours + " hora ";
        } else if (hours > 1) {
            finalStr += hours + " horas ";
        }

        if (minutes == 1) {
            finalStr += minutes + " minuto"
        } else if (minutes > 1) {
            finalStr += minutes + " minutos"
        }

        setTimeLeft(finalStr);
    }

    setInterval(function(){ 
        updateTimeLeft();
    }, 1000);

    function goToCourse() {
        navigate(`/curso/curso-tortas-de-cumpleanos`);
    }

    function tryFreeLesson() {
        fbTrackUtils.trackLead();
        navigate(`/curso/curso-tortas-de-cumpleanos`);
    }

    function closeInterestedModal() {
        setShowInterestedVideo(false);
    }

    function buy() {
        if (data.allMysqlAllCourses.nodes[0].canBuy) {
            setLoading(true);
            buyUtils.buy(userContext);
        } else {
            setShowInterestedVideo(true);
        }
    }

    function showIntroVideo() {
        setShowCoverVideo(true);
        fbTrackUtils.trackWatchLandingVideo();
    }

  return (
    <div className={mainStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Curso Tortas de Cumpleaños - Fede Cocina</title>
          <meta name="description" content="Curso Tortas de Cumpleaños"/>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
        </Helmet>
        <CourseNavBar></CourseNavBar>
        { loading ? <Loading></Loading> : <></> }
        { showCoverVideo ?
            <div className={videoCoverMainDiv} onClick={() => setShowCoverVideo(false)}>
                <iframe className={videoCoverIFrame} src="https://player.vimeo.com/video/735672432?h=76c9f8e462" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
        :
            <></>
        }
        { showInterestedVideo ? 
            <InterestedModal closeModalCallback={closeInterestedModal}></InterestedModal>
            :
            <></>
        }
        <div className={topSection}>
            <div className={topSectionShadowDiv}></div>
            <StaticImage className={topSectionImageDesktop} src="../../images/courses/Banner Tortas de Cumpleaños.png" alt="Banner" imgStyle={{objectFit: 'cover'}}/>
            <StaticImage className={topSectionImageMobile} src="../../images/courses/Banner Tortas de Cumpleaños Mobile.jpg" alt="Banner" imgStyle={{objectFit: 'cover'}}/>
            
            <div className={topSectionContent}>
                <div className={topSectionMainTitle}> Curso Tortas de Cumpleaños </div>
                <div className={topSectionRating}>
                    <Star size={18}/><Star size={18}/><Star size={18}/><Star size={18}/><Star size={18}/> 
                    <div className={topSectionRatingText}>
                        (187)
                    </div>
                </div>
                <div className={topSectionMainSubTitle}> Aprende como preparar tortas de cumpleaños con una presentación increíble, riquísimo sabor y de la forma más fácil y rápida. </div>
                <div className={topSectionPriceDiv}>
                    <div className={topSectionPrice}> {pricesContext.getCurrentPriceString(data.allMysqlAllCourses.nodes[0], 1)} </div>
                    <div className={topSectionPricePrevious}> (antes {pricesContext.getCurrentPriceString(data.allMysqlAllCourses.nodes[0], 2)}) </div>
                </div>
                <div className={topSectionBuyButtonDiv}>
                    <button className={topSectionBuyButton} type="submit" onClick={() => buy()}>{ data.allMysqlAllCourses.nodes[0].canBuy ? 
                    <div className={topSectionBuyInnerContainerButton}>
                        <div className={topSectionBuyMainTextButton}>Comprar Curso</div>
                        <StaticImage className={flag} src="../../images/courses/AR_flag.png" alt="Argentina"/>
                        <div className={bannerBuyButtonPrice}>
                            AR$ {pricesContext.getCurrentPriceStringPlain(data.allMysqlAllCourses.nodes[0], 1)}
                        </div>                            
                    </div>
                    : 
                        'Me Interesa' 
                    }</button>
                    <button className={topSectionFreeSectionButton} type="submit" onClick={() => tryFreeLesson()}>Probar una Lección Gratis</button>
                </div>
            </div>
        </div>
        <div className={mainContentDiv}>
            <div className={summaryMainDiv}>
                <div className={summaryLeftDiv}>
                    <div className={summaryPersonDiv}>
                        <StaticImage className={personImage} src="../../images/youtubeLogo.jpg" imgStyle={{objectFit: 'contain'}} width={130} height={130} alt="Federico Quevedo"/>
                        <div className={summaryPersonDetailsDiv}>
                            <div className={summaryPersonDetailsTopDiv}>
                                Chef pastelero, fundador de Fede Cocina
                            </div>
                            <div className={summaryPersonDetailsBottomDiv}>
                                Federico Quevedo
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className={summaryIncludedTitleDiv}>
                        ¿Qué incluye el curso?
                    </div>
                    <ul className={summaryIncludedUl}>
                    { included.map((include) => {              
                            return (
                                <li>
                                    <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none">
                                        <path d="M8 12.5L11 15.5L16 9.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {include}
                                </li>
                            )
                        })
                    }
                    </ul>
                    <hr></hr>
                    <div className={summaryButtonsDiv}>
                        <button className={summaryBuyButton} type="submit" onClick={() => buy()}>{ data.allMysqlAllCourses.nodes[0].canBuy ? 
                            <>  
                                Comprar 
                                <StaticImage className={flag} src="../../images/courses/AR_flag.png" alt="Argentina"/>
                                {pricesContext.getCurrentPriceString(data.allMysqlAllCourses.nodes[0], 1)} 
                            </>
                        : 
                            'Me Interesa' 
                        }</button>

                        <button className={summaryFreeSectionButton} type="submit" onClick={() => tryFreeLesson()}>Probar una Lección Gratis</button>
                    </div>
                    <div className={timeLeftDiv}>
                        <svg width={16} height={16} viewBox="0 0 512 512" enable-background="new 0 0 512 512">
                            <path d="m256,51.8c-112.6,0-204.2,91.6-204.2,204.2s91.6,204.2 204.2,204.2 204.2-91.6 204.2-204.2-91.6-204.2-204.2-204.2v-1.42109e-14zm0,449.2c-135.1,0-245-109.9-245-245s109.9-245 245-245 245,109.9 245,245-109.9,245-245,245z"/>
                            <path d="m344.1,276.4h-88.1c-11.3,0-20.4-9.1-20.4-20.4v-157.8c0-11.3 9.1-20.4 20.4-20.4 11.3,0 20.4,9.1 20.4,20.4v137.4h67.7c11.3,0 20.4,9.1 20.4,20.4 5.68434e-14,11.3-9.1,20.4-20.4,20.4z"/>
                        </svg>
                        ¡La oferta termina en <b>{timeLeft}</b>!
                    </div>
                </div>
                <div className={summaryRightDiv} onClick={() => showIntroVideo()}>
                    <span className={playButton}>&#9654;</span>
                    <div className={summaryRightImgShadow}></div>
                    <StaticImage className={summaryRightImg} src="../../images/courses/Video Thumbnail.png" alt="Video Thumbnail" imgStyle={{objectFit: 'cover'}}/>
                </div>
            </div>
            <div className={textImagesMainDiv}>
                <div className={textImagesLeftDiv}>
                    <div className={textImagesLeftTitleDiv}>
                    Prepara tortas de cumpleaños que se ven espectaculares.
                    </div>
                    <div className={textImagesLeftTextDiv}>
                        <p>Hacer una torta de cumpleaños que sea sencilla de realizar, rica y al mismo tiempo visualmente atractiva, puede llegar a ser un gran desafío con el que todos nos encontramos en algún momento.</p>
                        <p>Este curso te va a ser muy útil tanto si estás queriendo aprender como preparar una torta de cumpleaños para un evento propio, de un familiar/amigo, o también si estás queriendo incursionar en un emprendimiento gastronómico.</p>
                        <p>&nbsp;</p>
                        <p>Cuando termines este curso vas a poder:</p>
                        <p>- Conocer todas las partes que componen una buena torta, con variaciones, y como armarla para que te quede igual que como las ves acá.</p>
                        <p>- Diseñar tus propias tortas acorde al evento que necesites cubrir.</p>
                        <p>- Estimar el tamaño de la torta según la cantidad de personas.</p>
                        <p>- Aplicar técnicas de decoración para lograr resultados profesionales.</p>
                    </div>
                </div>
                <div className={textImagesRightDiv}>
                    <StaticImage className={textImagesRightImage} src="../../images/courses/tortas de cumpleaños 1.png" alt="image1" imgStyle={{objectFit: 'contain'}}/>
                </div>
            </div>
            <div className={courseContentDiv}>
                <div className={courseContentTitleDiv}>
                    Contenidos del curso
                </div>
                <div className={courseContentSectionsActionsMainDiv}>
                    <div className={courseContentSectionsActionDiv} onClick={() => showAllContents()}>
                        ↓ Mostrar todo
                    </div>
                    <div className={courseContentSectionsActionSeparatorDiv}>
                        |
                    </div>
                    <div className={courseContentSectionsActionDiv} onClick={() => hideAllContents()}>
                        ↑ Ocultar
                    </div>
                </div>
                <div className={courseContentSectionsMainDiv}>
                    { data.allMysqlAllCourses.nodes[0].courseChapters.map((item, index) => {
                        return (
                            <div className={contentsOpened[index] ? courseContentSectionOpenedDiv : courseContentSectionClosedDiv}>
                                <div className={courseContentSectionInnerDiv}>
                                    <div className={courseContentSectionInnerNumberDiv} onClick={() => openContent(index)}>
                                        {(index + 1)}
                                    </div>
                                    <div className={courseContentSectionInnerNameDiv} onClick={() => openContent(index)}>
                                        {item.name}
                                    </div>
                                    <div className={courseContentSectionInnerArrowDiv} onClick={() => openContent(index)}>
                                        <i className={contentsOpened[index] ? arrowUp : arrowDown}></i>
                                    </div>
                                </div>
                                { contentsOpened[index] ?
                                        <div className={courseContentChapterSectionsMainDiv}>
                                            {item.courseSections.map((item, index) => {
                                                return (
                                                    <div className={courseContentChapterSectionMainDiv} onClick={() => goToCourse()}>
                                                        {item.icon == 'video' ?
                                                            <svg className={courseContentChapterSectionIconSvg} width="22px" height="22px" viewBox="0 0 28 28">
                                                                <path className={courseContentChapterSectionIconPath} d="M5.25 5.5C3.45507 5.5 2 6.95508 2 8.75V19.25C2 21.0449 3.45507 22.5 5.25 22.5H15.7523C17.5472 22.5 19.0023 21.0449 19.0023 19.25V17.6707L23.5434 20.7824C24.3729 21.3508 25.4999 20.7568 25.4999 19.7512V8.24842C25.4999 7.24298 24.3732 6.64898 23.5436 7.21708L19.0023 10.3272V8.75C19.0023 6.95508 17.5472 5.5 15.7523 5.5H5.25ZM19.0023 15.8524V12.1452L23.9999 8.72263V19.2769L19.0023 15.8524ZM17.5023 8.75V19.25C17.5023 20.2165 16.7187 21 15.7523 21H5.25C4.2835 21 3.5 20.2165 3.5 19.25V8.75C3.5 7.7835 4.2835 7 5.25 7H15.7523C16.7188 7 17.5023 7.7835 17.5023 8.75Z" fill="#212121"/>
                                                            </svg>
                                                            :
                                                            <></>
                                                        }
                                                        {item.icon == 'text' ?
                                                            <svg className={courseContentChapterSectionIconSvg} width="22px" height="22px" viewBox="0 0 1024 1024">
                                                                <path className={courseContentChapterSectionIconPath} d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 01-32 32H160a32 32 0 01-32-32V96a32 32 0 0132-32zm160 448h384v64H320v-64zm0-192h160v64H320v-64zm0 384h384v64H320v-64z"/>
                                                            </svg>
                                                            :
                                                            <></>
                                                        }
                                                        <div className={courseContentChapterSectionNameAndFreeDiv}>
                                                            <div className={courseContentChapterSectionNameDiv}>
                                                                {item.name}
                                                            </div>
                                                            {item.isFree == 1 ?
                                                                <div className={courseContentChapterSectionFreeDiv}>
                                                                    SECCIÓN GRATUITA
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <></>
                                    }
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
        <CourseCarousel></CourseCarousel>
        <div className={mainContentDiv}>
            <div className={textImagesMainDiv}>
                <div className={textImagesRightDiv}>
                    <StaticImage className={textImagesRightImage} src="../../images/courses/tortas de cumpleaños 2.png" alt="image1" imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className={textImagesLeftDiv}>
                    <div className={textImagesLeftTitleDiv}>
                        Conoce en profundidad cada una de las partes que componen una torta increíble
                    </div>
                    <div className={textImagesLeftTextDiv}>
                        <p>Cuando uno comienza a incursionarse en la pastelería se encuentra con miles de recetas y un centenar de formas de preparaciones que, muchas veces, termina confundiéndonos y dándonos la impresión de que esto es algo muy complejo y que no es para cualquiera.</p>
                        <p>Esto sucede ya que las recetas no se nos presentan de manera ordenada, ni se explica porque se agrega cada ingrediente o porque se realiza cierta preparación.</p>
                        <p>Por eso, a lo largo de este curso te voy a enseñar en detalle cada una de las preparaciones, mostrando su receta, procedimiento, variaciones posibles y explicando que función cumple cada ingrediente y porque se ejecuta cada paso.</p>
                        <p>Mi objetivo es que al finalizar el curso te sientas confiando, no solo para preparar las recetas, sino también para poder experimentar y modificarlas a tu gusto.</p>
                    </div>
                    <div className={textImagesLeftSecondTitleDiv}>
                        ¿A quién está dirigido este curso?
                    </div>
                    <div className={textImagesLeftSecondTextDiv}>
                        <p>Este curso está pensando tanto para personas que no tienen ninguna experiencia en pastelería, como para quienes son expertos y están buscando aprender otras alternativas en sus preparaciones.</p>
                    </div>
                    <div className={textImagesLeftSecondTitleDiv}>
                        Material necesario
                    </div>
                    <ul className={materialsUl}>
                    { materials.map((material) => {              
                            return (
                                <li>
                                    <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none">
                                        <path d="M8 12.5L11 15.5L16 9.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {material}
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
            <div className={teacherMainDiv}>
                <div className={teacherTitleDiv}>
                    Profesor
                </div>
                <div className={teacherContentMainDiv}>
                    <div className={teacherContentLeftDiv}>
                        <div className={teacherContentLeftOuterDiv}>
                            <StaticImage className={teacherContentLeftImg} src="../../images/youtubeLogo.jpg" width={300} height={300} imgStyle={{objectFit: 'contain'}} alt="Federico Quevedo"/>
                        </div>
                    </div>
                    <div className={teacherContentRightDiv}>
                        <div className={teacherContentRightTitleDiv}>
                            Chef pastelero, fundador de Fede Cocina
                        </div>
                        <div className={teacherContentRightSecondDiv}>
                            Federico Quevedo
                        </div>
                        <div className={teacherContentRightTextDiv}>
                            <p>Comencé mi camino en la enseñanza de pastelería en el año 2020 creando el canal de Youtube Fede Cocina.</p>
                            <p>A la fecha, contando con más de 2 millones de reproducciones y más de 200 recetas enseñadas, decidí crear esta plataforma online.</p>
                            <p>Recibido en el Instituto Argentino de Gastronomía, acompaño a personas como vos a descubrir y potenciar la pasión por la cocina.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={reviewsMainDiv}>
                <div className={reviewsTitleDiv}>
                    Nuestros alumnos opinan
                </div>
                <CourseReviewsCarousel></CourseReviewsCarousel>
            </div>
            <div className={faqMainDiv}>
                <div className={faqTitleDiv}>
                    Preguntas Frecuentes
                </div>
                { faq.map((entry) => {              
                        return (
                            <>
                                <div className={faqQuestionDiv}>
                                    {entry.question}
                                </div>
                                <div className={faqAnswerDiv}>
                                    {entry.answer}
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
        <Footer></Footer>
        <div className={scrollY < 270 ? hideDiv : fixedBottomBarMainDiv}>
            <div className={fixedBottomBarInnerDiv}>
                <button className={fixedBottomBuyButton} type="submit" onClick={() => buy()}>
                    { data.allMysqlAllCourses.nodes[0].canBuy ? 
                    <div className={fixedBottomBuyInnerDiv}>
                        <div className={fixedBottomBuyMainTextButton}>
                            Comprar&nbsp;
                        </div>
                        <StaticImage className={flag} src="../../images/courses/AR_flag.png" alt="Argentina"/>
                        {pricesContext.getCurrentPriceString(data.allMysqlAllCourses.nodes[0], 1)}
                    </div>
                    :
                        'Me Interesa' 
                    }
                    <span className={fixedBottomBuyButtonPreviousPrice}>(antes {pricesContext.getCurrentPriceString(data.allMysqlAllCourses.nodes[0], 2)})</span>
                </button>
                <button className={fixedBottomFreeSectionButton} type="submit" onClick={() => tryFreeLesson()}>Probar una Lección Gratis</button>
            </div>
        </div>
    </div>
    )
}

export default IndexPage;
