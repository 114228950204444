import { mainDiv, ldsRoller } from './Loading.module.css'
import * as React from "react"

const Loading = () => {

    return (
        <div class={mainDiv}>
            <div class={ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loading