import { mainContainer, mainDiv, mainUl, optionLi, logoDiv, logoImg, 
  mainOptionsDiv, topMainDiv, optionLoginLink, optionCoursesLink, optionLogoutButton } from './CourseNavBar.module.css'
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import { navigate } from "gatsby"
import useWindowSize from "../../utils/useWindowSize";
import userContext from "../../utils/userContext"

const CourseNavBar = ({updateOnKeyPress, showFixedSearch}) => {
  const [isUserLogValid, setIsUserLogValid] = React.useState(false);
  const [startLearningUrl, setStartLearningUrl] = React.useState('/login/');
  const { width } = useWindowSize();

  function logout() {
    userContext.logout();
    setIsUserLogValid(false);
  }

  useEffect(() => {
    if (userContext.isLogged()) {
      userContext.loadUser().then(resultStatus => {
        if (resultStatus == "OK") {
          setIsUserLogValid(true);
          if (userContext.isSuscribedToCourse(userContext.user.lastCourseId)) {
            setStartLearningUrl('curso/' + userContext.user.lastCourse);
          }
        } else {
          setIsUserLogValid(false);
        }
      });
    }
  }, [])

  return (
    <nav className={mainContainer}>
      <div className={mainDiv}>
        <div className={topMainDiv}>
          <div className={logoDiv}>
            <a href="/" aria-label="Home">
              <StaticImage className={logoImg} src="../images/logoTextOnly.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
            </a>
          </div>
          <div className={mainOptionsDiv}>
            <ul className={mainUl}>
              <li className={optionLi}>
                {isUserLogValid ?
                  <button className={optionLogoutButton} onClick={() => logout()}> Salir </button>
                :
                  <Link className={optionLoginLink} to="/login/"> Entrar </Link>
                }
              </li>
              <li className={optionLi}>
                <Link className={optionCoursesLink} to={startLearningUrl}> Comenza a Aprender </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default CourseNavBar
