import cookiesUtils from "./cookiesUtils"

var fbTrackUtils = {
    trackContact: trackContact,
    trackInitiateCheckout: trackInitiateCheckout,
    trackPurchase: trackPurchase,
    trackViewContent: trackViewContent,
    trackCompleteRegistration: trackCompleteRegistration,
    trackLead: trackLead,
    trackLongViewContent: trackLongViewContent,
    trackWatchLandingVideo: trackWatchLandingVideo,
}

var trackWatchLandingVideoSent = false;

function trackContact() {
    if (typeof window !== "undefined" && window.fbq != null) {
        window.fbq('track', 'Contact');
    }
}

function trackInitiateCheckout() {
    if (typeof window !== "undefined" && window.fbq != null) {
        window.fbq('track', 'InitiateCheckout');
    }
}

function trackPurchase() {
    var purchaseTracked = cookiesUtils.getCookie('purchaseTracked');
    if (purchaseTracked) {
        return;
    }

    if (typeof window !== "undefined" && window.fbq != null) {
        window.fbq('track', 'Purchase', {value: 1290, currency: 'ARS'});

        cookiesUtils.setCookie('purchaseTracked', true, 30);
    }
}

function trackViewContent() {
    if (typeof window !== "undefined" && window.fbq != null) {
        window.fbq('track', 'ViewContent');
    }
}

function trackCompleteRegistration() {
    if (typeof window !== "undefined" && window.fbq != null) {
        window.fbq('track', 'CompleteRegistration');
    }
}

function trackLead() {
    if (typeof window !== "undefined" && window.fbq != null) {
        window.fbq('track', 'Lead');
    }
}

function trackLongViewContent() {
    if (typeof window !== "undefined" && window.fbq != null) {
        setTimeout(trackLongViewContentFunc, 5000);
    }
}

function trackLongViewContentFunc() {
    window.fbq('trackCustom', 'LongViewContent')
}

function trackWatchLandingVideo() {
    if (!trackWatchLandingVideoSent) {
        trackWatchLandingVideoSent = true;
        window.fbq('trackCustom', 'WatchLandingVideo')
    }
}

export default fbTrackUtils;