// extracted by mini-css-extract-plugin
export var mainDiv = "CourseReviewsCarousel-module--mainDiv--2EZfm";
export var mainInnerDiv = "CourseReviewsCarousel-module--mainInnerDiv--1C1uw";
export var slideContainerDiv = "CourseReviewsCarousel-module--slideContainerDiv--3ZN89";
export var slideContainerInnerDiv = "CourseReviewsCarousel-module--slideContainerInnerDiv--2qJtT";
export var slider = "CourseReviewsCarousel-module--slider--1e8Bs";
export var starsDiv = "CourseReviewsCarousel-module--starsDiv--3SOVQ";
export var titleDiv = "CourseReviewsCarousel-module--titleDiv--38eEA";
export var authorDiv = "CourseReviewsCarousel-module--authorDiv--1DvbY";
export var reviewTextDiv = "CourseReviewsCarousel-module--reviewTextDiv--15GPC";
export var moreLessInfoDiv = "CourseReviewsCarousel-module--moreLessInfoDiv--f9GYQ";
export var moreLessInfoHiddenDiv = "CourseReviewsCarousel-module--moreLessInfoHiddenDiv--1cqOj";