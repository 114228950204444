import { interestedModalBackgroundDiv, interestedModalMainDiv, interestedModalLeftFormDiv, interestedModalLeftFormFullDiv,
    interestedModalLeftFormLabelLineDiv, interestedModalLeftFormLabelDiv, interestedModalLeftFormErrorDiv,
    interestedModalLeftFormInputDiv, interestedModalInnerDiv, sendButtonDiv, sendButton, sendButtonDisabled,
    interestedModalTitle, interestedModalText, interestedModalCloseButtonDiv, interestedModalCloseButtonSvg,
    interestedModalCloseButtonPath, sendErrorDiv, successSvg, successPath, successMainDiv, successText,
    interestedModalSuccessInnerDiv } from './InterestedModal.module.css'
import React, { useState } from "react"
  
  const InterestedModal = ({closeModalCallback}) => {
  
    const [showLoading, setShowLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        mail: '',
    });

    const [formError, setFormError] = useState({
        name: '',
        mail: '',
    });

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    function sendData() {
        var isValid = true;
        var formErrorCopy = JSON.parse(JSON.stringify(formError));
        if (formData.name.length < 2) {
            isValid = false;
            formErrorCopy['name'] = "Este campo es obligatorio";
        } else {
            formErrorCopy['name'] = "";
        }
        if (formData.mail.length < 5) {
            isValid = false;
            formErrorCopy['mail'] = "Este campo es obligatorio";
        } else {
            formErrorCopy['mail'] = "";
        }

        setFormError(formErrorCopy);

        if (!isValid) {
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        setShowLoading(true);
        fetch(`${process.env.GATSBY_API_URL}api/course/saveInterest`, requestOptions)
        .then(response => response.text())
        .then(resultData => {
            setShowLoading(false);
            if (resultData == "OK") {
                setShowSuccess(true);
            } else {
                setShowError(true);
            }
        }).catch(error => {
            console.log(error);
            setShowLoading(false);
        })
    }

    function closeModal() {
        closeModalCallback();
    }

    return (
        <div className={interestedModalBackgroundDiv}>
            <div className={interestedModalMainDiv}>
                { showSuccess ?
                    <div className={interestedModalSuccessInnerDiv}>
                        <div className={interestedModalCloseButtonDiv} onClick={() => closeModal()}>
                            <svg className={interestedModalCloseButtonSvg} width="32px" height="32px" viewBox="0 0 24 24"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path className={interestedModalCloseButtonPath} d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
                        </div>
                        <div className={successMainDiv}>
                            <svg className={successSvg} width="110" height="110" viewBox="0 0 512 512">
                                <path className={successPath} d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M386.594,226.664 L252.747,360.511c-7.551,7.551-17.795,11.794-28.475,11.794s-20.923-4.243-28.475-11.795l-70.388-70.389 c-15.726-15.726-15.726-41.223,0.001-56.95c15.727-15.725,41.224-15.726,56.95,0.001l41.913,41.915l105.371-105.371 c15.727-15.726,41.223-15.726,56.951,0.001C402.319,185.44,402.319,210.938,386.594,226.664z"/>
                            </svg>
                            <div className={successText}>
                                Te estaremos contactando muy pronto! &#128522;
                            </div>
                        </div>
                    </div>
                :
                <div className={interestedModalInnerDiv}>
                    <div className={interestedModalCloseButtonDiv} onClick={() => closeModal()}>
                        <svg className={interestedModalCloseButtonSvg} width="32px" height="32px" viewBox="0 0 24 24"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path className={interestedModalCloseButtonPath} d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
                    </div>
                    <div className={interestedModalTitle}>
                        Enterate antes que nadie!
                    </div>
                    <div className={interestedModalText}>
                        Dejanos tus datos y te vamos a avisar apenas este disponible el curso.
                    </div>
                    <div className={interestedModalLeftFormDiv}>
                        <div className={interestedModalLeftFormFullDiv}>
                            <div className={interestedModalLeftFormLabelLineDiv}>
                                <div className={interestedModalLeftFormLabelDiv}>
                                    Nombre
                                </div>
                                { formError.name ?
                                    <div className={interestedModalLeftFormErrorDiv}>
                                        {formError.name}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <input className={interestedModalLeftFormInputDiv} name="name" value={formData.name} onChange={handleInputChange} placeholder='Nombre*' maxLength={50}></input>
                        </div>
                    </div>
                    <div className={interestedModalLeftFormDiv}>
                        <div className={interestedModalLeftFormFullDiv}>
                            <div className={interestedModalLeftFormLabelLineDiv}>
                                <div className={interestedModalLeftFormLabelDiv}>
                                    Correo electrónico
                                </div>
                                { formError.mail ?
                                    <div className={interestedModalLeftFormErrorDiv}>
                                        {formError.mail}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <input className={interestedModalLeftFormInputDiv} name="mail" value={formData.mail} onChange={handleInputChange} placeholder='Correo electrónico*' maxLength={50}></input>
                        </div>
                    </div>
                    { showError ?
                        <div className={sendErrorDiv}>Ha ocurrido un error, vuelve a intentarlo más tarde.</div>
                        :
                        <></>
                    }
                    <div className={sendButtonDiv}>
                        <button className={showLoading ? sendButtonDisabled : sendButton} type="submit" disabled={showLoading} onClick={sendData}>Enviar</button>
                    </div>
                </div>
                }
            </div>
        </div>
    )
  }
  
  export default InterestedModal
  