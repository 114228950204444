import fbTrackUtils from "./fbTrackUtils"

var buyUtils = {
    buy: buy,
}

function buy(userContext) {
    fbTrackUtils.trackInitiateCheckout();

    var bodyData = {
        courseId: 1,
        countryId: 1,
        userId: userContext.user ? userContext.user.id : null
    };

    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData)
    };

    fetch(`${process.env.GATSBY_API_URL}api/mercadoPago/createPayment`, requestOptions)
        .then(response => response.json())
        .then(resultData => {
            if (resultData && resultData.redirect) {
                window.location = resultData.redirect;
            }
        }
    )
}

export default buyUtils;