import * as React from "react"

const Star = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" style={{margin: "2px"}}>
            <path d="M12,17.27L18.18,21l-1.64,-7.03L22,9.24l-7.19,-0.61L12,2 9.19,8.63 2,9.24l5.46,4.73L5.82,21z" stroke="#FBAE10" fill="#FBAE10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default Star