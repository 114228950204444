// extracted by mini-css-extract-plugin
export var mainContainer = "CourseNavBar-module--mainContainer--1DNYz";
export var mainDiv = "CourseNavBar-module--mainDiv--2w40r";
export var mainUl = "CourseNavBar-module--mainUl--Ntmf8";
export var optionLi = "CourseNavBar-module--optionLi--2qCuD";
export var logoDiv = "CourseNavBar-module--logoDiv---2tlT";
export var logoImg = "CourseNavBar-module--logoImg--3XWWb";
export var mainOptionsDiv = "CourseNavBar-module--mainOptionsDiv--1m5A8";
export var topMainDiv = "CourseNavBar-module--topMainDiv--3DkDa";
export var optionLoginLink = "CourseNavBar-module--optionLoginLink--2tnfZ";
export var optionCoursesLink = "CourseNavBar-module--optionCoursesLink--2o0wo";
export var optionLogoutButton = "CourseNavBar-module--optionLogoutButton--33fTg";