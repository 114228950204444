var pricesContext = {
    getCurrentPriceString: getCurrentPriceString,
    getCurrentPriceStringPlain: getCurrentPriceStringPlain
}

function getCurrentPriceString(courseData, mult) {
    var currentCountryPrice = null;

    for (var i = 0; i < courseData.coursePrices.length; i++) {
        if (courseData.coursePrices[i].coursePriceCountries[0].cod_country == "AR") {
            currentCountryPrice = courseData.coursePrices[i];
            break;
        }
    }

    var country = currentCountryPrice.coursePriceCountries[0];

    return country.coin_symbol + numberWithPoints(currentCountryPrice.price * mult) + " " + country.coin_cod;
}

function getCurrentPriceStringPlain(courseData, mult) {
    var currentCountryPrice = null;

    for (var i = 0; i < courseData.coursePrices.length; i++) {
        if (courseData.coursePrices[i].coursePriceCountries[0].cod_country == "AR") {
            currentCountryPrice = courseData.coursePrices[i];
            break;
        }
    }

    return numberWithPoints(currentCountryPrice.price * mult);
}

function numberWithPoints(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export default pricesContext;