// extracted by mini-css-extract-plugin
export var mainStyles = "curso-tortas-de-cumpleanos-module--mainStyles--HlYS3";
export var topSection = "curso-tortas-de-cumpleanos-module--topSection--KMuA1";
export var topSectionShadowDiv = "curso-tortas-de-cumpleanos-module--topSectionShadowDiv--34On7";
export var topSectionImageDesktop = "curso-tortas-de-cumpleanos-module--topSectionImageDesktop--2hPWZ";
export var topSectionImageMobile = "curso-tortas-de-cumpleanos-module--topSectionImageMobile--3bmhJ";
export var topSectionMainTitle = "curso-tortas-de-cumpleanos-module--topSectionMainTitle--1VAwS";
export var topSectionContent = "curso-tortas-de-cumpleanos-module--topSectionContent--1aSPh";
export var topSectionMainSubTitle = "curso-tortas-de-cumpleanos-module--topSectionMainSubTitle--1drpp";
export var topSectionBuyButtonDiv = "curso-tortas-de-cumpleanos-module--topSectionBuyButtonDiv--17aOk";
export var topSectionBuyButton = "curso-tortas-de-cumpleanos-module--topSectionBuyButton--3lrH6";
export var topSectionFreeSectionButton = "curso-tortas-de-cumpleanos-module--topSectionFreeSectionButton--1aDS2";
export var topSectionPriceDiv = "curso-tortas-de-cumpleanos-module--topSectionPriceDiv--R7fbI";
export var topSectionPrice = "curso-tortas-de-cumpleanos-module--topSectionPrice--3j6nT";
export var topSectionPricePrevious = "curso-tortas-de-cumpleanos-module--topSectionPricePrevious--2gYtu";
export var mainContentDiv = "curso-tortas-de-cumpleanos-module--mainContentDiv--3l4l_";
export var textImagesMainDiv = "curso-tortas-de-cumpleanos-module--textImagesMainDiv--21ymH";
export var textImagesLeftDiv = "curso-tortas-de-cumpleanos-module--textImagesLeftDiv--7RjpG";
export var textImagesRightDiv = "curso-tortas-de-cumpleanos-module--textImagesRightDiv--1saPU";
export var textImagesLeftTitleDiv = "curso-tortas-de-cumpleanos-module--textImagesLeftTitleDiv--4DVFD";
export var textImagesLeftSecondTitleDiv = "curso-tortas-de-cumpleanos-module--textImagesLeftSecondTitleDiv--3AW9T";
export var textImagesLeftTextDiv = "curso-tortas-de-cumpleanos-module--textImagesLeftTextDiv--3fhYB";
export var textImagesLeftSecondTextDiv = "curso-tortas-de-cumpleanos-module--textImagesLeftSecondTextDiv--31_wt";
export var textImagesRightImage = "curso-tortas-de-cumpleanos-module--textImagesRightImage--3TTaK";
export var courseContentDiv = "curso-tortas-de-cumpleanos-module--courseContentDiv--1N3dD";
export var courseContentTitleDiv = "curso-tortas-de-cumpleanos-module--courseContentTitleDiv--33Z0s";
export var courseContentSectionsMainDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionsMainDiv--LbDSM";
export var courseContentSectionClosedDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionClosedDiv--2d9wX";
export var courseContentSectionOpenedDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionOpenedDiv--3QjrL";
export var courseContentSectionInnerDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionInnerDiv--3y3ik";
export var courseContentSectionInnerNumberDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionInnerNumberDiv--Md4-J";
export var courseContentSectionInnerNameDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionInnerNameDiv--183XT";
export var arrowUp = "curso-tortas-de-cumpleanos-module--arrowUp--13S7d";
export var arrowDown = "curso-tortas-de-cumpleanos-module--arrowDown--1YXJL";
export var courseContentSectionInnerArrowDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionInnerArrowDiv--37U_6";
export var courseContentChapterSectionsMainDiv = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionsMainDiv--BvKNU";
export var courseContentChapterSectionMainDiv = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionMainDiv--3BVBE";
export var courseContentChapterSectionNameDiv = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionNameDiv--39U2p";
export var courseContentChapterSectionFreeDiv = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionFreeDiv--3-4Na";
export var courseContentChapterSectionIconSvg = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionIconSvg--3CdCy";
export var courseContentChapterSectionIconPath = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionIconPath--f8gRJ";
export var courseContentChapterSectionNameAndFreeDiv = "curso-tortas-de-cumpleanos-module--courseContentChapterSectionNameAndFreeDiv--26ukH";
export var materialsUl = "curso-tortas-de-cumpleanos-module--materialsUl--2OxzZ";
export var faqMainDiv = "curso-tortas-de-cumpleanos-module--faqMainDiv--2x9-I";
export var faqTitleDiv = "curso-tortas-de-cumpleanos-module--faqTitleDiv--2Q94Q";
export var faqQuestionDiv = "curso-tortas-de-cumpleanos-module--faqQuestionDiv--2v8Ol";
export var faqAnswerDiv = "curso-tortas-de-cumpleanos-module--faqAnswerDiv--3NHj6";
export var summaryMainDiv = "curso-tortas-de-cumpleanos-module--summaryMainDiv--3vGEe";
export var summaryLeftDiv = "curso-tortas-de-cumpleanos-module--summaryLeftDiv--3F0Hf";
export var personImage = "curso-tortas-de-cumpleanos-module--personImage--2TP_7";
export var summaryPersonDiv = "curso-tortas-de-cumpleanos-module--summaryPersonDiv--134Jj";
export var summaryPersonDetailsDiv = "curso-tortas-de-cumpleanos-module--summaryPersonDetailsDiv--3uNZw";
export var summaryPersonDetailsTopDiv = "curso-tortas-de-cumpleanos-module--summaryPersonDetailsTopDiv--21xoW";
export var summaryPersonDetailsBottomDiv = "curso-tortas-de-cumpleanos-module--summaryPersonDetailsBottomDiv--1FZ6C";
export var summaryRightDiv = "curso-tortas-de-cumpleanos-module--summaryRightDiv--2vdIw";
export var summaryIncludedTitleDiv = "curso-tortas-de-cumpleanos-module--summaryIncludedTitleDiv--3OE2J";
export var summaryIncludedUl = "curso-tortas-de-cumpleanos-module--summaryIncludedUl--3As7n";
export var summaryButtonsDiv = "curso-tortas-de-cumpleanos-module--summaryButtonsDiv--22qIt";
export var summaryBuyButton = "curso-tortas-de-cumpleanos-module--summaryBuyButton--3YVuq";
export var summaryFreeSectionButton = "curso-tortas-de-cumpleanos-module--summaryFreeSectionButton--3lsaU";
export var timeLeftDiv = "curso-tortas-de-cumpleanos-module--timeLeftDiv--31peJ";
export var summaryRightImg = "curso-tortas-de-cumpleanos-module--summaryRightImg--3Z6cX";
export var summaryRightImgShadow = "curso-tortas-de-cumpleanos-module--summaryRightImgShadow--3yC6c";
export var playButton = "curso-tortas-de-cumpleanos-module--playButton--1YjzB";
export var courseContentSectionsActionsMainDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionsActionsMainDiv--1AGto";
export var courseContentSectionsActionDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionsActionDiv--1YmMr";
export var courseContentSectionsActionSeparatorDiv = "curso-tortas-de-cumpleanos-module--courseContentSectionsActionSeparatorDiv--3qEki";
export var teacherMainDiv = "curso-tortas-de-cumpleanos-module--teacherMainDiv--izOnB";
export var teacherTitleDiv = "curso-tortas-de-cumpleanos-module--teacherTitleDiv--1lsmg";
export var teacherContentMainDiv = "curso-tortas-de-cumpleanos-module--teacherContentMainDiv--15pv9";
export var teacherContentLeftOuterDiv = "curso-tortas-de-cumpleanos-module--teacherContentLeftOuterDiv--37zeT";
export var teacherContentLeftImg = "curso-tortas-de-cumpleanos-module--teacherContentLeftImg--3jEFO";
export var teacherContentLeftDiv = "curso-tortas-de-cumpleanos-module--teacherContentLeftDiv--tWVQy";
export var teacherContentRightDiv = "curso-tortas-de-cumpleanos-module--teacherContentRightDiv--2VyQk";
export var teacherContentRightTitleDiv = "curso-tortas-de-cumpleanos-module--teacherContentRightTitleDiv--1cnoB";
export var teacherContentRightSecondDiv = "curso-tortas-de-cumpleanos-module--teacherContentRightSecondDiv--2xV6j";
export var teacherContentRightTextDiv = "curso-tortas-de-cumpleanos-module--teacherContentRightTextDiv--3BImP";
export var fixedBottomBarMainDiv = "curso-tortas-de-cumpleanos-module--fixedBottomBarMainDiv--3Whqo";
export var fixedBottomBarInnerDiv = "curso-tortas-de-cumpleanos-module--fixedBottomBarInnerDiv--3BwKQ";
export var fixedBottomBuyButton = "curso-tortas-de-cumpleanos-module--fixedBottomBuyButton--1QLN4";
export var fixedBottomFreeSectionButton = "curso-tortas-de-cumpleanos-module--fixedBottomFreeSectionButton--3K2Wy";
export var hideDiv = "curso-tortas-de-cumpleanos-module--hideDiv--1ROiR";
export var videoCoverMainDiv = "curso-tortas-de-cumpleanos-module--videoCoverMainDiv--NlzBB";
export var videoCoverIFrame = "curso-tortas-de-cumpleanos-module--videoCoverIFrame--2LClf";
export var fixedBottomBuyButtonPreviousPrice = "curso-tortas-de-cumpleanos-module--fixedBottomBuyButtonPreviousPrice--mC9Zj";
export var topSectionRating = "curso-tortas-de-cumpleanos-module--topSectionRating--10y0B";
export var topSectionRatingText = "curso-tortas-de-cumpleanos-module--topSectionRatingText--1AUe6";
export var reviewsMainDiv = "curso-tortas-de-cumpleanos-module--reviewsMainDiv--3ikCU";
export var reviewsTitleDiv = "curso-tortas-de-cumpleanos-module--reviewsTitleDiv--dOB-i";
export var flag = "curso-tortas-de-cumpleanos-module--flag--3qumZ";
export var bannerBuyButtonPrice = "curso-tortas-de-cumpleanos-module--bannerBuyButtonPrice--z1zsX";
export var fixedBottomBuyInnerDiv = "curso-tortas-de-cumpleanos-module--fixedBottomBuyInnerDiv--37wrG";
export var topSectionBuyMainTextButton = "curso-tortas-de-cumpleanos-module--topSectionBuyMainTextButton--1tu3M";
export var fixedBottomBuyMainTextButton = "curso-tortas-de-cumpleanos-module--fixedBottomBuyMainTextButton--2BhBy";
export var topSectionBuyInnerContainerButton = "curso-tortas-de-cumpleanos-module--topSectionBuyInnerContainerButton--3GBn6";