import { mainDiv, mainInnerDiv, slider, slideContainerDiv, starsDiv, titleDiv, authorDiv, reviewTextDiv,
    slideContainerInnerDiv, moreLessInfoDiv, moreLessInfoHiddenDiv } from './CourseReviewsCarousel.module.css'
import * as React from "react"
import Slider from "react-slick";
import useWindowSize from "../../utils/useWindowSize";
import Star from "./Star"

const CourseCarousel = () => {
    
    var entries = [
        {
            'title': 'Curso muy completo y recomendable',
            'author': 'Carolina E',
            'reviewPartial': 'Hice el curso pensando que solo iba a abarcar algunas preparaciones básicas, pero encontré que Federico explica muchas técnicas y da ...',
            'reviewFull': 'Hice el curso pensando que solo iba a abarcar algunas preparaciones básicas, pero encontré que Federico explica muchas técnicas y da ideas que me ayudaron a preparar muchas más tipos de torta de lo que esperaba, muy recomendable!',
        },
        {
            'title': 'Muy útil',
            'author': 'GONZALO A',
            'reviewPartial': 'Muchas cosas que aprendí en el curso me sirvieron para otras preparaciones...',
            'reviewFull': 'Muchas cosas que aprendí en el curso me sirvieron para otras preparaciones de pastelería, vale la pena.',
        },
        {
            'title': 'Excelente Curso',
            'author': 'Marlene T',
            'reviewPartial': 'Este no es el primer curso de pastelería online que hago pero si uno de los que más me gustaron...',
            'reviewFull': 'Este no es el primer curso de pastelería online que hago pero si uno de los que más me gustaron, Federico se toma su tiempo para explicar técnicas que parecen complicadas pero de una forma muy simple, estoy muy conforme con los contenidos.',
        },
        {
            'title': 'Me gusto mucho',
            'author': 'rocio o',
            'reviewPartial': 'El curso cumple con lo que promete.',
            'reviewFull': 'El curso cumple con lo que promete.',
        }
    ]

    const { width } = useWindowSize();
    const [ contentsOpened, setContentsOpened ] = React.useState(getInitialContentsOpened(entries));

    function getInitialContentsOpened(entries) {
        var contentsOpenedTmp = [];
        for (var i = 0; i < entries.length; i++) {
            contentsOpenedTmp[i] = false;
        }

        return contentsOpenedTmp;
    }

    function openContent(index) {
        setContentsOpened(contentsOpened => {
            return contentsOpened.map((item, j) => {
              return j === index ? !item : item
            })
        })
      }

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: width < 900 ? 1 : 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000
    };

    return (
        <div className={mainDiv} id="courseReviewsCarousel">
            <div className={mainInnerDiv}>
                <Slider {...settings} className={slider}>
                    { entries.map((item, index) => {
                        return (
                            <div className={slideContainerDiv}>
                                <div className={slideContainerInnerDiv}>
                                    <div className={starsDiv}>
                                        <Star size={14}/><Star size={14}/><Star size={14}/><Star size={14}/><Star size={14}/>
                                    </div>
                                    <div className={titleDiv}>
                                    {item['title']}
                                    </div>
                                    <div className={authorDiv}>
                                        {item['author']}
                                    </div>
                                    <div className={reviewTextDiv}>
                                        {contentsOpened[index] ? item['reviewFull'] : item['reviewPartial']}
                                    </div>
                                    { item['reviewFull'] != item['reviewPartial'] ?
                                    <>
                                        <div className={contentsOpened[index] ? moreLessInfoHiddenDiv : moreLessInfoDiv} onClick={() => openContent(index)}>
                                            Más Información
                                        </div>
                                        <div className={contentsOpened[index] ? moreLessInfoDiv : moreLessInfoHiddenDiv} onClick={() => openContent(index)}>
                                            Menos Información
                                        </div>
                                    </>
                                    :
                                    <></>
                                    }
                                </div>
                            </div>
                        )
                    })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default CourseCarousel