import React from "react";

export default function useWindowSscroll() {
  const [windowScroll, setWindowScroll] = React.useState({
    scrollY: typeof window !== "undefined" ? window.scrollY : 1200
  });

  function changeWindowScroll() {
    setWindowScroll({ scrollY: window.scrollY });
  }

  React.useEffect(() => {
    window.addEventListener("scroll", changeWindowScroll);

    return () => {
      window.removeEventListener("scroll", changeWindowScroll);
    };
  }, []);

  return windowScroll;
}
